<style lang="scss" scoped>
.nav_menu {
	float: right;
	font-size: 0.3rem;
	margin-left: -2em;
}
.nav_share {
	float: left;
	font-size: 0.3rem;
	margin-right: -2em;
}
.qrcode_cont {
	text-align: center;
	.qrcode {
		width: 4rem;
		height: 4rem;
	}
	h4 {
		margin-top: 0;
	}
}
.title {
	width: calc(100% - 4em);
	margin-left: 2em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>

<template>
	<div>
		<top-nav :showBack="hasBack" @back="back">
			<span v-if="hasShare" class="nav_share" @click="share">分享</span>
			<span v-if="hasLogout" class="nav_menu" @click="logout">退出</span>
			<div class="title"><slot></slot></div>
		</top-nav>

		<van-dialog v-model="shareShow" title="分享" confirmButtonText="关闭" confirmButtonColor="black">
			<div class="qrcode_cont">
				<img class="qrcode" :src="qrcode_img" alt="" />
				<h4>长按二维码转发给朋友</h4>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import { Dialog } from 'vant';
import { http_logout, http_getUserInfo } from '@/request/common';
import QRCode from 'qrcode';

export default {
	name: 'headerNav',
	components: {
		[Dialog.Component.name]: Dialog.Component,
	},
	props: {
		hasBack: {
			type: [String, Boolean],
			required: false,
			default: true,
		},
		hasLogout: {
			type: [String, Boolean],
			required: false,
			default: true,
		},
		hasShare: {
			type: [String, Boolean],
			required: false,
			default: false,
		},
	},
	data() {
		return {
			shareShow: false,
			qrcode_img: '',
		};
	},
	created() {},
	methods: {
		back() {
			this.$router.go(-1);
		},
		logout() {
			http_logout().then(() => {
				this.$router.push({
					path: 'login',
					query: {
						pid: this.$route.query.pid,
					},
				});
			});
		},

		getUserDesc() {
			return new Promise(resolve => {
				http_getUserInfo().then(res => {
					resolve(res.userDesc);
				});
			});
		},

		async share() {
			let userDesc = await this.getUserDesc();
			let url = `${location.origin}/sundry?user=${userDesc}`;
			QRCode.toDataURL(url, {
				width: 800,
				height: 800,
			}).then(res => {
				this.qrcode_img = res;
				this.shareShow = true;
			});
		},
	},
};
</script>
